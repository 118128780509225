export const hopeDiagnosticSubsidyZendeskTemplate = ({ enrollmentData }) => {
  let {
    firstName,
    lastName,
    middleInitial,
    emailAddress,
    mobileNumber,
    birthday,
    prescribingDoctor,
    hospitalClinicName,
    otherHospital,
    partnerLaboratory,
    dateOfXray,
    dateOfPulmonaryTest,
  } = enrollmentData

  let hospitalName =
    hospitalClinicName.value === "Other"
      ? otherHospital
      : hospitalClinicName.value

  let parsedBirthday = ""
  if (birthday?.month?.value && birthday?.date?.value && birthday?.year)
    parsedBirthday = `${birthday?.month.value} ${birthday?.date.value} ${birthday?.year}`

  let parsedDateOfXray = ""
  if (dateOfXray?.month?.value && dateOfXray?.date?.value && dateOfXray?.year)
    parsedDateOfXray = `${dateOfXray?.month.value} ${dateOfXray?.date.value} ${dateOfXray?.year}`

  let parsedDateOfPulmonaryTest = ""
  if (
    dateOfPulmonaryTest?.month?.value &&
    dateOfPulmonaryTest?.date?.value &&
    dateOfPulmonaryTest?.year
  )
    parsedDateOfPulmonaryTest = `${dateOfPulmonaryTest?.month.value} ${dateOfPulmonaryTest?.date.value} ${dateOfPulmonaryTest?.year}`

  return `Patient Details
  First Name: ${firstName}
  Last Name: ${lastName}
  Middle Initial: ${middleInitial || "N/A"}
  Email: ${emailAddress}
  Mobile Number: ${mobileNumber}
  Birthday: ${parsedBirthday || "N/A"}
  Prescribing Doctor: ${prescribingDoctor}
  Hospital or Clinic: ${hospitalName}
  -----
  Partner Laboratory: ${partnerLaboratory}
  Date of Chest X-ray: ${parsedDateOfXray || "N/A"}
  Date of Pulmonary Function Test (PFT): ${parsedDateOfPulmonaryTest || "N/A"}`
}
