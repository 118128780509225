import {
  parsePublicFundings,
  parsePrivateFundings,
  parseIncomeInformation,
} from "./financialAssessmentSummary"

export const defaultValues = [
  "",
  undefined,
  "null null ",
  "Metro Manila, ",
  "undefined undefined undefined",
]

export const parseSummaryValue = (data, type, state = {}) => {
  switch (type) {
    case "text":
      if( data === "I am a new patient: I have just been prescribed Nintedanib (Ofev) and have not yet started treatment" ) return "New"
      else if( data === "I am an existing patient: I have already started treatment with Nintedanib (Ofev)" ) return "Existing"
      else return data
    case "array":
      return data?.join(", ")
    case "date":
      if (data?.month.value && data?.date.value && data?.year)
        return `${data?.month.value} ${data?.date.value} ${data?.year}`
      return ""
    case "address":
      let addressList = ""

      addressList = `${data?.addressType ? `${data?.addressType}, ` : ""}${
        data?.streetAddress ? `${data?.streetAddress}, ` : ""
      }${data?.province?.value ? `${data?.province?.value}, ` : ""}
      ${data?.city?.value ? `${data?.city?.value}` : ""}${
        data?.notes ? `, ${data?.notes}` : ""
      }`

      return addressList
    case "diagnosis":
      if (
        data ===
        "New patient: I have just been prescribed with Afatinib (Giotrif) and have not yet started treatment"
      )
        return "New"
      return "Existing"
    case "sourceOfIncome":
      return parseIncomeInformation(data, state)
    case "publicFunding":
      return parsePublicFundings(state)
    case "privateFunding":
      return parsePrivateFundings(state)
    default:
      return null
  }
}

export const shouldSummarySubmitBeDisabled = ({ module, state }) => {
  const { consentAndAuth, documents, medicineAvailment } = state;

  let prescriptionInDocuments = documents.filter((document) => {
    return document.filename.startsWith("rx");
  })

  switch (module) {
    // case "medicine-assistance":
    //   return !state?.medicineAssistance?.existingUserMAConsent?.length > 0
    case "financial-assessment":
      return !state?.faDeclaration?.length > 0
    case "medicine-availment":
      return ( consentAndAuth.length === 0 || prescriptionInDocuments.length === 0 || !medicineAvailment?.desiredMedicine?.value );
    default:
      return false
  }
}
