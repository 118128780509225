import React from "react"
import classNames from "classnames"
import { Field, ErrorMessage } from "formik"

const FormTextArea = ({
  label,
  isRequired,
  hideOptional,
  placeholder,
  children,
  name,
  rows,
  value,
  readOnly,
  maxLength,
  helper,
}) => {
  let finalMaxLength = maxLength
  if (!finalMaxLength) finalMaxLength = 500
  return (
    <div className="field mb-1">
      {!!label && (
        <label
          className={classNames("is-size-5 label has-text-weight-normal", {
            "has-text-grey-dark": !isRequired,
          })}
        >
          {label}
          {!isRequired && !hideOptional && (
            <span className="has-text-grey is-italic"> (Optional)</span>
          )}
          {!!helper && (
            <span className={classNames("help has-text-weight-normal")}>
              {helper}
            </span>
          )}
        </label>
      )}
      <div className="field-body">
        <div className="field">
          <p className="control">
            <Field name={name}>
              {({ field }) => (
                <textarea
                  className="textarea has-fixed-size"
                  placeholder={placeholder}
                  name={name}
                  rows={rows}
                  value={value}
                  {...field}
                  readOnly={readOnly}
                  maxLength={finalMaxLength}
                >
                  {children}
                </textarea>
              )}
            </Field>
          </p>
          <ErrorMessage name={name}>
            {error => <div className="has-text-danger is-size-7">{error}</div>}
          </ErrorMessage>
        </div>
      </div>
    </div>
  )
}

export default FormTextArea
