import { GATSBY_ENV } from "gatsby-env-variables"
import { capitalize } from "../../../services/general"
import { sendToZendesk } from "../../../services/zendeskService"

import { handleError } from "../../../services/handleError"
import {
  patientEnrollmentTicketTemplate,
  medicineAssistanceTicketTemplate,
} from "../templates/formTicketTemplate"

import { medicineAvailmentZendeskTemplate } from "../templates/medicineAvailmentTemplate"
import { hopePatientEnrollmentZendeskTemplate } from "../templates/hopePatientEnrollment"
import { financialAssessmentZendeskTemplate } from "../templates/financialAssessmentTicket"
import { hopeDiagnosticSubsidyZendeskTemplate } from "../templates/diagnosticSubsidyTickekTemplate"

import { parseZendeskFields } from "./zendeskFields"
import { GATSBY_GIOTRIF_ZENDESK_FIELD } from "gatsby-env-variables"

const fetchPayload = type => {
  switch (type) {
    case "hope-patient":
      return {
        type: "patient_enrollment",
        template: hopePatientEnrollmentZendeskTemplate,
      }
    case "patient":
      return {
        type: "patient_enrollment",
        template: patientEnrollmentTicketTemplate,
      }
    case "hope-diagnostic-subsidy":
      return {
        type: "diagnostic_subsidy",
        template: hopeDiagnosticSubsidyZendeskTemplate,
      }
    case "medicine-assistance":
      return {
        type: "medicine_assistance",
        template: medicineAssistanceTicketTemplate,
      }

    case "financial-assessment":
      return {
        type: "financial_assessment",
        template: financialAssessmentZendeskTemplate,
      }
    case "medicine-availment":
      return {
        type: "medicine_availment",
        template: medicineAvailmentZendeskTemplate,
      }
    default:
      return {
        type: "",
        template: "",
      }
  }
}

export const sendEnrollmentFormToZendesk = config => {
  let { enrollmentData, documents, ticketTemplate, type, app } = config // Removed file array
  let isTest = GATSBY_ENV !== "production"
  let isNewFa = type === "financial_assessment"

  let tags = [type, `bm_${app}`]
  if (isTest) tags.push("test")
  if (isNewFa) tags.push("new")

  let subject = `${isTest ? "[TEST] " : ""}${capitalize(
    type.replace("_", " ")
  )} form of ${enrollmentData?.firstName} ${enrollmentData?.lastName}`

  if (type === "medicine_availment") {
    let test = enrollmentData.receipts.forEach(item => {
      delete item.document
    })
  }

  let requestBody = {
    type: "request",
    tags,
    subject,
    requester: {
      name: `${enrollmentData?.firstName} ${enrollmentData?.lastName}`,
      email: enrollmentData?.emailAddress,
    },
    custom_fields: [
      {
        id: GATSBY_GIOTRIF_ZENDESK_FIELD,
        value: JSON.stringify(
          parseZendeskFields({ enrollmentData, module: type })
        ),
      },
    ],
    comment: { body: ticketTemplate({ enrollmentData }) },
  }

  return sendToZendesk(requestBody || {}, documents || [])
}

export const handleSubmitForm = async ({
  type,
  enrollmentData,
  documents,
  callback,
  errorCallback,
  appKey,
  app,
}) => {
  let submitPayload = fetchPayload(appKey || type)

  try {
    await sendEnrollmentFormToZendesk({
      enrollmentData,
      documents,
      ticketTemplate: submitPayload.template,
      type: submitPayload.type,
      app,
    })
    if (callback) callback()
  } catch (error) {
    let errorMessage =
      "There was an error sending your enrollment. Please try again."
    handleError({ error, errorCallback, errorMessage })
  }
}

export const checkFileUploads = (docTypes, state) => {
  for (let index in docTypes) {
    let documentCount = state?.documents.filter(document =>
      document.name.startsWith(docTypes[index])
    ).length
    if (documentCount === 0) return true
  }
  return false
}
