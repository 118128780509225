export const medicineAvailmentZendeskTemplate = ({ enrollmentData }) => {
  let {
    firstName,
    lastName,
    middleInitial,
    emailAddress,
    mobileNumber,
    secondaryContactPerson,
    secondaryContactNumber,
    deliveryAddress,
    desiredMedicine,
    receipts,
  } = enrollmentData

  let { addressType, streetAddress, province, city } = deliveryAddress

  let salesInvoiceUploaded = ""
  receipts.map((receipt, index) => {
    let {
      salesInvoiceNumber,
      subdistributor,
      purchaseDate,
      lineItems,
    } = receipt

    lineItems.map(item => {
      if (item?.isOrdered?.length > 0)
        salesInvoiceUploaded += `${index +
          1}.) Sales Invoice Number: ${salesInvoiceNumber}\nSubdistributor: ${
          subdistributor.value
        }\nPurchase: ${`${purchaseDate.month.value} ${purchaseDate.date.value} ${purchaseDate.year}`}\nItem: ${
          item.lineItem
        }\nTablets Ordered: ${item.tabletsOrdered}
        \n`
    })
  })

  return `
Requested Medicine Assistance -----
${desiredMedicine.value}  

Sales Invoice Uploaded -----

${salesInvoiceUploaded}
Delivery Details -----
First Name: ${firstName}
Last Name: ${lastName}
Middle Initial: ${middleInitial || "N/A"}
Email Address: ${emailAddress}
Mobile Number: ${mobileNumber}

Secondary Contact Person: ${secondaryContactPerson}
Mobile Number of Contact Person: ${secondaryContactNumber}

Address Type: ${addressType}
Street Address: ${streetAddress}
Province: ${province.value}
City: ${city.value}`
}
