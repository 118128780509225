export const parseIncomeInformation = (sourceOfIncome, state) => {
  let sourcesOfIncome = []

  sourceOfIncome.includes("Savings") &&
    sourcesOfIncome.push(`Savings: ${state.patientsSavings}`)
  sourceOfIncome.includes("Salary or wage") &&
    sourcesOfIncome.push(`Salary or wage: ${state.patientMonthlyIncome}`)
  sourceOfIncome.includes("Business") &&
    sourcesOfIncome.push(`Business: ${state.monthlyBusinessIncome}`)
  sourceOfIncome.includes("Practice of profession") &&
    sourcesOfIncome.push(`Practice of profession: ${state.monthlyIncome}`)
  sourceOfIncome.includes("Commissions") &&
    sourcesOfIncome.push(`Commissions: ${state.monthlyAverage}`)
  sourceOfIncome.includes("Farms, haciendas, or fish ponds") &&
    sourcesOfIncome.push(
      `Farms, haciendas, or fish ponds: ${state.propertyMonthlyIncome}`
    )
  sourceOfIncome.includes("Real estate, house, or room rentals") &&
    sourcesOfIncome.push(
      `Real estate, house, or room rentals: ${state.estateMonthlyIncome}`
    )
  sourceOfIncome.includes("Earnings from investments") &&
    sourcesOfIncome.push(
      `Earnings from investments: ${state.investmentMonthlyAverage}`
    )
  sourceOfIncome.includes("Pensions") &&
    sourcesOfIncome.push(`Pension: ${state.monthlyPension}`)
  sourceOfIncome.includes("Remittance") &&
    sourcesOfIncome.push(`Remittance: ${state.monthlyRemittance}`)
  sourceOfIncome.includes("Family support") &&
    sourcesOfIncome.push(`Family support: ${state.monthlySupport}`)
  sourceOfIncome.includes("Other (please specify)") &&
    sourcesOfIncome.push(`Other: ${state.otherSource} - ${state.monthlyAmount}`)

  return sourcesOfIncome.join(", ") || "None"
}

export const parsePublicFundings = data => {
  let publicFundings = []

  data.publicAmountPhilHealth &&
    publicFundings.push(`PhilHealth: ${data.publicAmountPhilHealth}`)
  data.publicAmountDSWD && publicFundings.push(`DSWD: ${data.publicAmountDSWD}`)
  data.publicAmountDOH && publicFundings.push(`DOH: ${data.publicAmountDOH}`)
  data.publicAmountPAGCOR &&
    publicFundings.push(`PAGCOR: ${data.publicAmountPAGCOR}`)
  data.publicAmountPCSO && publicFundings.push(`PCSO: ${data.publicAmountPCSO}`)
  data.publicAmountOWWA && publicFundings.push(`OWWA: ${data.publicAmountOWWA}`)
  data.publicAmountOfficeOfThePresident &&
    publicFundings.push(
      `Office of the President: ${data.publicAmountOfficeOfThePresident}`
    )
  data.publicAmountOfficeOfTheVicePresident &&
    publicFundings.push(
      `Office of the Vice President: ${data.publicAmountOfficeOfTheVicePresident}`
    )
  data.publicAmountOfficeOfTheMayorRepresentative &&
    publicFundings.push(
      `Office of the Mayor or Congressional Representative: ${data.publicAmountOfficeOfTheMayorRepresentative}`
    )
  data.publicAmountSSSGSISLoan &&
    publicFundings.push(`SSS or GSIS Loan: ${data.publicAmountSSSGSISLoan}`)
  data.publicAmountOtherName &&
    publicFundings.push(
      `${data.publicAmountOtherName}: ${data.publicAmountOtherAmount}`
    )

  return publicFundings.join(", ") || "None"
}

export const parsePrivateFundings = data => {
  let privateFundings = []

  data.privateAmountCompanyHealthBenefit &&
    privateFundings.push(
      `Company Health Benefit: ${data.privateAmountCompanyHealthBenefit}`
    )
  data.privateAmountPersonalHMO &&
    privateFundings.push(`Personal HMO: ${data.privateAmountPersonalHMO}`)
  data.privateAmountPersonalInsurance &&
    privateFundings.push(
      `Personal insurance: ${data.privateAmountPersonalInsurance}`
    )
  data.privateAmountFoundationsNGOs &&
    privateFundings.push(
      `Foundations or NGOs: ${data.privateAmountFoundationsNGOs}`
    )
  data.privateAmountPersonalBankLoansCredits &&
    privateFundings.push(
      `Personal bank loans or credits: ${data.privateAmountPersonalBankLoansCredits}`
    )
  data.privateAmountOtherName &&
    privateFundings.push(
      `${data.privateAmountOtherName}: ${data.privateAmountOtherAmount}`
    )

  return privateFundings.join(", ") || "None"
}
