import { errorZendeskTemplate } from "./templates/errorZendeskTicket"
import { GATSBY_ENV, GATSBY_SUBDOMAIN } from "gatsby-env-variables"
import { sendToZendesk } from "./zendeskService"

const sendErrorTicket = async error => {
  const IS_TEST = GATSBY_ENV !== "production"
  let tags = ["website_error"]

  if (IS_TEST) {
    tags.push("test")
  }

  let subject = `${
    IS_TEST ? "[TEST] " : ""
  }Website error from ${GATSBY_SUBDOMAIN}`

  let errorPayload = {
    subdomain: GATSBY_SUBDOMAIN,
    error,
    path: window.location.pathname,
    env: GATSBY_ENV,
  }

  let requestBody = {
    type: "incident",
    tags,
    subject,
    requester: {
      name: `MedGrocer IT`,
      email: "it@medgrocer.com",
    },
    comment: {
      body: errorZendeskTemplate(errorPayload),
    },
  }

  await sendToZendesk(requestBody, [])
}

export const handleError = async ({ error, errorCallback, errorMessage }) => {
  try {
    await sendErrorTicket(error)
  } finally {
    const NETWORK_ERROR_MESSAGE = "Network Error"

    if (error.message === NETWORK_ERROR_MESSAGE)
      errorMessage =
        "Submission Error: Please check your internet connection and try again."

    errorCallback({
      message: errorMessage,
    })
  }
}
