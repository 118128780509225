export const patientEnrollmentTicketTemplate = ({ enrollmentData }) => {
  let {
    firstName,
    lastName,
    middleInitial,
    emailAddress,
    mobileNumber,
    landline,
    contactedThrough,
    prescribingDoctor,
    hospitalClinicName,
    otherHospital,
    prescribedMedicine,
    diagnosis,
    otherDiagnosis,
    diagnosisDate,
    isExistingPatient,
  } = enrollmentData

  let hospitalName =
    hospitalClinicName.value === "Other"
      ? otherHospital
      : hospitalClinicName.value

  let diagnosisStartDate = ""
  if (
    diagnosisDate?.month?.value &&
    diagnosisDate?.date?.value &&
    diagnosisDate?.year
  )
    diagnosisStartDate = `${diagnosisDate?.month.value} ${diagnosisDate?.date.value} ${diagnosisDate?.year}`

  let currentDiagnosis =
    diagnosis.value === "Other (please specify)"
      ? otherDiagnosis
      : diagnosis.value

  let patientType =
    isExistingPatient ===
    "New patient: I have just been prescribed with Afatinib (Giotrif) and have not yet started treatment"
      ? "New"
      : "Existing"

  return `Patient Details
First Name: ${firstName}
Last Name: ${lastName}
Middle Initial: ${middleInitial || "N/A"}
Email: ${emailAddress}
Mobile Number: ${mobileNumber}
Landline: ${landline || "N/A"}
---
Communication Preferences
${contactedThrough.join(", ")}
---
Prescribing Doctor: ${prescribingDoctor}
Clinic or Hospital: ${hospitalName}
Prescribed Medicine: ${prescribedMedicine.value}
Diagnosis: ${currentDiagnosis}
Diagnosis Date: ${diagnosisStartDate || "N/A"}
Patient Type: ${patientType}`
}

export const medicineAssistanceTicketTemplate = ({ enrollmentData }) => {
  let {
    firstName,
    lastName,
    middleInitial,
    emailAddress,
    mobileNumber,
    prescribedDosage,
    dailyIntake,
    medicationStartDate,
    subdistributor,
  } = enrollmentData

  let medicationStartDateInfo = `${medicationStartDate?.month.value} ${medicationStartDate?.date.value} ${medicationStartDate?.year}`

  return `Personal Details
First Name: ${firstName}
Last Name: ${lastName}
Middle Initial: ${middleInitial || "N/A"}
Email: ${emailAddress}
Mobile Number: ${mobileNumber}
---
Prescribing Dosage: ${prescribedDosage.value}
Daily Intake: 1
Medication Start Date: ${medicationStartDateInfo}
Subdistributor: ${subdistributor.value}`
}
