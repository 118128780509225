import React, { useContext, useState, useEffect } from "react"
import classNames from "classnames"
import { navigate } from "gatsby"
import { get } from "lodash"

import Layout from "layout/Layout"
import FormAddons from "./FormAddons"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import EditDetailsButton from "elements/EditDetailsButton"
import PrescriptionDetails from "elements/PrescriptionDetails"

import { getFormValues } from "./services/values"
import { getEncryptedKey } from "./services/keys"
import styles from "./utils/enrollment.module.scss"
import { AppContext } from "../../context/AppContext"
import { RESET_STATE } from "../../context/AppReducer"
import { handleSubmitForm } from "./services/enrollment"
import { shouldSummarySubmitBeDisabled } from "./services/summary"
import { parseSummaryValue, defaultValues } from "./services/summary"
import { initial } from "lodash-es"

const Summary = props => {
  const { backPath, nextPath, module, summaryConfig } = props.pageContext

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const { state, dispatch } = useContext(AppContext)

  let initialValues = getFormValues({ state, module: module.name })

  const handleSuccessCallback = () => {
    setLoading(false)
  }

  const handleSubmitError = ({ message }) => {
    setErrorMessage(message)
    setLoading(false)
  }

  useEffect(() => {
    if (getEncryptedKey(`${module.app}-${module.name}`)) {
      navigate(backPath)
    }
  }, [backPath])

  const handleSubmit = () => {
    setLoading(true)
    handleSubmitForm({
      app: module.app,
      type: module.name,
      appKey: module.appKey,
      enrollmentData: initialValues,
      documents: state.documents,
      callback: () => {
        handleSuccessCallback()
        dispatch({
          type: RESET_STATE,
        })
        navigate(nextPath)
      },
      errorCallback: handleSubmitError,
    })
  }

  let uploadBackRoute = "#upload-prescription"
  if (module.name === "financial-assessment") uploadBackRoute = ""

  let uploadBackPath = `${backPath}/${uploadBackRoute}`
  if (module.name === "medicine-availment")
    uploadBackPath = `/${module.app}/${module.name}/upload`

  return (
    <Layout
      title={module.title}
      seoTitle={module.seoTitle}
      subtitle={module.subtitle}
    >
      <Container isCentered>
        <FormAddons
          addon={
            module.addon === "medicine-availment"
              ? "medicine-availment-summary"
              : ""
          }
        />
        {summaryConfig.map(section => {
          let hasSummaryData = false
          let customBackPath = ""

          if (section.customRoute)
            customBackPath = `/${module.app}/${module.name}/${section.customRoute}`

          if (module.name === "financial-assessment") {
            if (module.app === "giotrif")
              customBackPath = `/${module.app}/${module.name}/${section.customRoute}`
            else customBackPath = `/${module.name}/${section.customRoute}`
          }

          return (
            <span>
              <Section
                title={section.section}
                isRequired
                addOns={{
                  right: (
                    <EditDetailsButton
                      route={`${customBackPath || backPath}#${section.route}`}
                    />
                  ),
                }}
              >
                <div className="table-container mb-3">
                  <table class="table is-fullwidth is-size-5">
                    <tbody>
                      {section.fields.map(field => {
                        hasSummaryData = true

                        if (field.key === "otherSpecialization") {
                          let specialization = parseSummaryValue(
                            get(initialValues, "specialization.value"),
                            "text"
                          )

                          if (specialization !== "Other (please specify)")
                            return null
                        }

                        if (field.key === "otherHospital") {
                          let hospitalClinic = parseSummaryValue(
                            get(initialValues, "hospitalClinicName.value"),
                            "text"
                          )

                          if (hospitalClinic !== "Other") return null
                        }

                        if (field.key === "otherDiagnosis") {
                          let diagnosis = parseSummaryValue(
                            get(initialValues, "diagnosis.value"),
                            "text"
                          )

                          if (diagnosis !== "Other (please specify)")
                            return null
                        }

                        if (field.key === "typeOfResidence") {
                          let residence = parseSummaryValue(
                            get(initialValues, "typeOfResidence"),
                            "text"
                          )

                          if (residence === "Other (please specify)")
                            return null
                        }

                        if (field.key === "monthsAsPatient") {
                          let isExisting = parseSummaryValue(
                            get(initialValues, "isExistingPatient"),
                            "text"
                          )

                          if (
                            isExisting ===
                            "I am a new patient: I have just been prescribed Nintedanib (Ofev) and have not yet started treatment"
                          )
                            return null
                        }

                        return (
                          <tr>
                            <td className="has-text-weight-bold">
                              {field.label}
                            </td>
                            <td
                              className={classNames(
                                styles["summary__tableData"]
                              )}
                            >
                              {parseSummaryValue(
                                get(initialValues, field.key),
                                field.type,
                                initialValues
                              ) || "N/A"}
                            </td>
                          </tr>
                        )
                      })}
                      {!hasSummaryData && (
                        <Message color="warning">
                          No data found for {section.section.toLowerCase()}{" "}
                          section.
                        </Message>
                      )}
                    </tbody>
                  </table>
                </div>
              </Section>
              {state.documents.length > 0 &&
                section?.section === "Income Information" && (
                  <Section
                    title="Proof of Income"
                    isRequired
                    addOns={{
                      right: <EditDetailsButton route={uploadBackPath} />,
                    }}
                  >
                    <PrescriptionDetails />
                  </Section>
                )}
            </span>
          )
        })}
        {state.documents.length > 0 && module?.name !== "financial-assessment" && (
          <Section
            title="Documents Uploaded"
            isRequired
            addOns={{
              right: <EditDetailsButton route={uploadBackPath} />,
            }}
          >
            <PrescriptionDetails />
          </Section>
        )}
        <FormAddons addon={module.addon} />
        {errorMessage && <Message color="danger">{errorMessage}</Message>}
        <ActionButtons
          back={{
            label: "Back",
            callback: () => {
              navigate(backPath)
            },
          }}
          submit={{
            label: "Submit",
            handleClick: handleSubmit,
            disabled: shouldSummarySubmitBeDisabled({
              state,
              module: module.name,
            }),
            loading,
          }}
        />
      </Container>
    </Layout>
  )
}

export default Summary
