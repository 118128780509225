export const parseZendeskFields = ({ enrollmentData, module }) => {
  let parsedData = enrollmentData

  switch (module) {
    case "patient_enrollment":
      let {
        hospitalClinicName,
        otherHospital,
        diagnosis,
        otherDiagnosis,
        diagnosisDate,
        isExistingPatient,
        prescribedMedicine,
      } = parsedData

      let hospitalName =
        hospitalClinicName.value === "Other"
          ? otherHospital
          : hospitalClinicName.value

      let diagnosisStartDate = ""
      if (
        diagnosisDate?.month?.value &&
        diagnosisDate?.date?.value &&
        diagnosisDate?.year
      )
        diagnosisStartDate = `${diagnosisDate?.month.value} ${diagnosisDate?.date.value} ${diagnosisDate?.year}`

      let currentDiagnosis =
        diagnosis.value === "Other (please specify)"
          ? otherDiagnosis
          : diagnosis.value

      let patientType =
        isExistingPatient ===
        "New patient: I have just been prescribed with Afatinib (Giotrif) and have not yet started treatment"
          ? "New"
          : "Existing"

      parsedData.parsedContactedThrough = parsedData.contactedThrough.join(", ")
      parsedData.parsedPatientType = patientType
      parsedData.parsedCurrentDiagnosis = currentDiagnosis
      parsedData.parsedDiagnosisStartDate = diagnosisStartDate
      parsedData.parsedHospitalName = hospitalName
      parsedData.parsedMedicine = `${prescribedMedicine?.value} - Medicine Assistance`
      break
    case "medicine_assistance":
      let { medicationStartDate } = parsedData

      let parsedMedicationStartDate = ""
      if (
        medicationStartDate?.month?.value &&
        medicationStartDate?.date?.value &&
        medicationStartDate?.year
      )
        parsedMedicationStartDate = `${medicationStartDate?.month.value} ${medicationStartDate?.date.value} ${medicationStartDate?.year}`

      parsedData.parsedMedicationStartDate = parsedMedicationStartDate
      parsedData.parsedDailyIntake = 1

      break
    case "diagnostic_subsidy":
      let { birthday, dateOfXray, dateOfPulmonaryTest } = parsedData

      let parsedBirthday = ""
      if (birthday?.month?.value && birthday?.date?.value && birthday?.year)
        parsedBirthday = `${birthday?.month.value} ${birthday?.date.value} ${birthday?.year}`

      let parsedDateOfXray = ""
      if (
        dateOfXray?.month?.value &&
        dateOfXray?.date?.value &&
        dateOfXray?.year
      )
        parsedDateOfXray = `${dateOfXray?.month.value} ${dateOfXray?.date.value} ${dateOfXray?.year}`

      let parsedDateOfPulmonaryTestInfo = ""
      if (
        dateOfPulmonaryTest?.month?.value &&
        dateOfPulmonaryTest?.date?.value &&
        dateOfPulmonaryTest?.year
      )
        parsedDateOfPulmonaryTestInfo = `${dateOfPulmonaryTest?.month.value} ${dateOfPulmonaryTest?.date.value} ${dateOfPulmonaryTest?.year}`

      let selectedHospitalName =
        parsedData?.hospitalClinicName?.value === "Other"
          ? parsedData?.otherHospital
          : parsedData?.hospitalClinicName?.value

      parsedData.parsedBirthday = parsedBirthday
      parsedData.parsedDateOfXray = parsedDateOfXray
      parsedData.parsedDateOfPulmonaryTestInfo = parsedDateOfPulmonaryTestInfo
      parsedData.hospitalName = selectedHospitalName
      break
  }

  return parsedData
}
