export const financialAssessmentZendeskTemplate = ({ enrollmentData }) => {
  let {
    firstName,
    lastName,
    middleInitial,
    emailAddress,
    mobileNumber,
    yearsOfResidency,
    civilStatus,
    numberOfDependents,
    philHealthNumber,
    sssGSISNumber,
    tinNumber,

    sourceOfIncome,
    patientsSavings,
    patientMonthlyIncome,
    monthlyBusinessIncome,
    monthlyIncome,
    monthlyAverage,
    propertyMonthlyIncome,
    estateMonthlyIncome,
    investmentMonthlyAverage,
    sourceOfPension,
    monthlyPension,
    sourceOfRemittance,
    monthlyRemittance,
    sourceOfSupport,
    monthlySupport,
    otherSource,
    monthlyAmount,
    patientEmploymentStatus,
    typeOfResidence,
    mortgagedMonthlyAmortization,
    monthlyRental,
    otherResidenceStatus,
    otherResidenceStatusMonthly,
    sizeOfResidence,
    hasVehicle,
    numberOfVehiclesOwned,
    kindsOfVehicleOwned,

    publicAmountPhilHealth,
    publicAmountDSWD,
    publicAmountDOH,
    publicAmountPAGCOR,
    publicAmountPCSO,
    publicAmountOWWA,
    publicAmountOfficeOfThePresident,
    publicAmountOfficeOfTheMayorRepresentative,
    publicAmountSSSGSISLoan,
    publicAmountOfficeOfTheVicePresident,
    publicAmountOtherName,
    publicAmountOtherAmount,

    privateAmountCompanyHealthBenefit,
    privateAmountPersonalHMO,
    privateAmountPersonalInsurance,
    privateAmountFoundationsNGOs,
    privateAmountPersonalBankLoansCredits,
    privateAmountOtherName,
    privateAmountOtherAmount,

    monthlyLiabilitiesAndExpenses,
  } = enrollmentData

  let sourcesOfIncome = []
  sourceOfIncome.includes("Savings") &&
    sourcesOfIncome.push(`Savings: ${patientsSavings}`)
  sourceOfIncome.includes("Salary or wage") &&
    sourcesOfIncome.push(`Salary or wage: ${patientMonthlyIncome}`)
  sourceOfIncome.includes("Business") &&
    sourcesOfIncome.push(`Business: ${monthlyBusinessIncome}`)
  sourceOfIncome.includes("Practice of profession") &&
    sourcesOfIncome.push(`Practice of profession: ${monthlyIncome}`)
  sourceOfIncome.includes("Commissions") &&
    sourcesOfIncome.push(`Commissions: ${monthlyAverage}`)
  sourceOfIncome.includes("Farms, haciendas, or fish ponds") &&
    sourcesOfIncome.push(
      `Farms, haciendas, or fish ponds: ${propertyMonthlyIncome}`
    )
  sourceOfIncome.includes("Real estate, house, or room rentals") &&
    sourcesOfIncome.push(
      `Real estate, house, or room rentals: ${estateMonthlyIncome}`
    )
  sourceOfIncome.includes("Earnings from investments") &&
    sourcesOfIncome.push(
      `Earnings from investments: ${investmentMonthlyAverage}`
    )
  sourceOfIncome.includes("Pensions") &&
    sourcesOfIncome.push(`Pension: ${monthlyPension}`)
  sourceOfIncome.includes("Remittance") &&
    sourcesOfIncome.push(`Remittance: ${monthlyRemittance}`)
  sourceOfIncome.includes("Family support") &&
    sourcesOfIncome.push(`Family support: ${monthlySupport}`)
  sourceOfIncome.includes("Other (please specify)") &&
    sourcesOfIncome.push(
      `Other (Please specify): ${otherSource} - ${monthlyAmount}`
    )

  let publicFunding = `${
    publicAmountPhilHealth ? `PhilHealth: ${publicAmountPhilHealth}, ` : ""
  }${publicAmountDSWD ? `DSWD: ${publicAmountDSWD}, ` : ""}${
    publicAmountDOH ? `DOH: ${publicAmountDOH}, ` : ""
  }${publicAmountPAGCOR ? `PAGCOR: ${publicAmountPAGCOR}, ` : ""}${
    publicAmountPCSO ? `PCSO: ${publicAmountPCSO}, ` : ""
  }${publicAmountOWWA ? `OWWA: ${publicAmountOWWA}, ` : ""}${
    publicAmountOfficeOfThePresident
      ? `Office of the President: ${publicAmountOfficeOfThePresident}, `
      : ""
  }${
    publicAmountOfficeOfTheVicePresident
      ? `Office of the Vice President: ${publicAmountOfficeOfTheVicePresident}, `
      : ""
  }${
    publicAmountOfficeOfTheMayorRepresentative
      ? `Office of the Mayor Representative: ${publicAmountOfficeOfTheMayorRepresentative}, `
      : ""
  }${
    publicAmountSSSGSISLoan ? `SSS or GSIS loan: ${publicAmountSSSGSISLoan}, ` : ""
  }${
    publicAmountOtherName
      ? `${publicAmountOtherName}: ${publicAmountOtherAmount}, `
      : ""
  }`

  let privateFunding = `${
    privateAmountCompanyHealthBenefit
      ? `Company Health Benefit: ${privateAmountCompanyHealthBenefit}, `
      : ""
  }${
    privateAmountPersonalHMO
      ? `Personal HMO: ${privateAmountPersonalHMO}, `
      : ""
  }${
    privateAmountPersonalInsurance
      ? `Personal insurance: ${privateAmountPersonalInsurance}, `
      : ""
  }${
    privateAmountFoundationsNGOs
      ? `Foundations or NGOs: ${privateAmountFoundationsNGOs}, `
      : ""
  }${
    privateAmountPersonalBankLoansCredits
      ? `Personal bank loans or credits: ${privateAmountPersonalBankLoansCredits}, `
      : ""
  }${
    privateAmountOtherName
      ? `${privateAmountOtherName}: ${privateAmountOtherAmount}, `
      : ""
  }`

  let food = monthlyLiabilitiesAndExpenses[0].amount
  let education = monthlyLiabilitiesAndExpenses[1].amount
  let transportation = monthlyLiabilitiesAndExpenses[2].amount
  let groceries = monthlyLiabilitiesAndExpenses[3].amount
  let clothingFootWear = monthlyLiabilitiesAndExpenses[4].amount
  let electricityBill = monthlyLiabilitiesAndExpenses[5].amount
  // let waterBill = monthlyLiabilitiesAndExpenses[6].amount
  // let internetBill = monthlyLiabilitiesAndExpenses[7].amount
  // let cableSubscriptionBill = monthlyLiabilitiesAndExpenses[8].amount
  // let telecommunication = monthlyLiabilitiesAndExpenses[9].amount
  // let otherLiabilitiesExpenses = monthlyLiabilitiesAndExpenses[10].amount
  // let otherLiabilitiesExpensesName = monthlyLiabilitiesAndExpenses[10].otherName
  // let householdPersonnel = monthlyLiabilitiesAndExpenses[11].amount
  // let loadsAndCredits = monthlyLiabilitiesAndExpenses[12].amount
  // let insurancePremium = monthlyLiabilitiesAndExpenses[13].amount

  // let vehicleAssets =
  //   hasVehicle === "Yes"
  //     ? `(${numberOfVehiclesOwned} - ${kindsOfVehicleOwned})`
  //     : "None"

  // let fullAddress = `${streetAddress}, ${city.value}, ${province.value}`

  return `Patient Details
  First Name: ${firstName}
  Last Name: ${lastName}
  Middle Initial: ${middleInitial || "N/A"}
  Email: ${emailAddress}
  Mobile Number: ${mobileNumber}
  -----
  Income Information
  Patient’s Employment Status: ${patientEmploymentStatus.value}
  Source of Income or Funds (monthly): ${
    sourcesOfIncome.length > 0 ? sourcesOfIncome.join(", ") : "None"
  }
  -----
  Alternative Funding Support or Financial Assistance Availed
  Public or government funding sources: ${
    publicFunding !== "" ? publicFunding : "None"
  }
  Private funding sources: ${privateFunding !== "" ? privateFunding : "None"}
  -----
  Monthly Expenses and Liabilities (Php)
  Food and groceries: ${food}
  Education: ${education}
  Transportation: ${transportation}
  Mortgage, rent, and other housing-related expenses: ${groceries}
  Utilities and other bills (e.g. electricity and water bills): ${clothingFootWear}
  Other expenses (e.g. clothing, salaries, and insurance premiums): ${electricityBill}`
}
